import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, Text, View } from "react-native";
import API from "../API";
import Storage from "../Storage";

import Screen from "../components/Screen";
import Loading from "../components/Loading";
import { H3, Container, InputGroup, TextInput, InputError, Button, IconButton, em } from "../components/Themed";
import { Formik } from "formik";
import * as Yup from "yup";

import AppContext from "../AppContext";

export default function LoginScreen(props) {
    const { navigation, ...otherProps } = props;
    const [accessToken, setAccessToken, User, setUser] = useContext(AppContext);
    const [loggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState(false);
    const [email, setEmail] = useState(false);

    useEffect(() => {
        Storage.email.get().then((value) => {
            setEmail(value ? value : "");
        });
    }, []);

    useEffect(() => {
        if (accessToken) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [accessToken]);

    const navigateBack = () => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate("Home");
        }
    };

    const navTo = (name) => {
        return () => navigation.navigate({ name: name });
    };

    const initialValues = { email: email, password: "" };
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().required("Required"),
    });

    const onSubmit = (values, { setSubmitting }) => {
        setError(false);
        Storage.email.set(values.email);
        API.Auth.login(values.email, values.password, "test")
            .then((response) => {
                console.log("Login then", response);
                setSubmitting(false);
                setAccessToken(response.data.access_token);
                setUser(response.data.user);
                navigateBack();
                return true;
            })
            .catch((error) => {
                console.log("Login catch", error);
                setSubmitting(false);
                if (error.response ?? false) {
                    console.log(error.response.data.error);
                    setError(error.response.data.error);
                } else if (error.message ?? false) {
                    setError(error.message);
                } else {
                    setError("Internal Server Error" + JSON.stringify(error, null, 2));
                    // code is error.response.status
                }
            });
        return;
    };

    const handleOneTimeCode = (values) => {
        console.log("values", values);
        navigation.navigate({ name: "OneTimeCode", params: values });
    };

    if (loggedIn) {
        return (
            <Screen style={styles.screen} navigation={navigation} {...props}>
                <Container style={styles.container}>
                    <H3>Logged In</H3>

                    <Button type="primary" title="Go back to home page" onPress={navTo("Home")} />
                </Container>
            </Screen>
        );
    }

    if (email !== false) {
        return (
            <Screen style={styles.screen} navigation={navigation} {...props}>
                <Formik style={styles.form} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} disabled>
                    {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, isSubmitting }) => (
                        <Container style={styles.container}>
                            <H3>Login</H3>
                            <InputGroup>
                                <TextInput placeholder="Email Address" onChangeText={handleChange("email")} onBlur={handleBlur("email")} value={values.email} keyboardType="email-address" />
                                {errors.email && <InputError>{errors.email}</InputError>}
                            </InputGroup>
                            <InputGroup>
                                <TextInput placeholder="Password" onChangeText={handleChange("password")} onBlur={handleBlur("password")} value={values.password} secureTextEntry />
                                {errors.password && <InputError>{errors.password}</InputError>}
                            </InputGroup>

                            {error && <InputError style={styles.error}>{error}</InputError>}
                            {isSubmitting && <Text style={styles.loading}>Loading...</Text>}

                            <Button onPress={handleSubmit} title="Login" disabled={!isValid || isSubmitting} />

                            <Text style={{ marginVertical: em(1), textAlign: "center" }}>OR</Text>
                            <Button type="success" onPress={() => handleOneTimeCode(values)} title="Send One-time Login Code" />
                        </Container>
                    )}
                </Formik>

                <IconButton type="primary" title="Register a New Account" onPress={navTo("Register")} />
            </Screen>
        );
    } else {
        return <Loading>Loading</Loading>;
    }
}

const styles = StyleSheet.create({
    form: {
        flex: 1,
        alignContent: "center",
    },
    screen: {},
    container: {},
    error: {
        paddingBottom: em(1),
    },
    loading: {
        paddingBottom: em(1),
    },
});
