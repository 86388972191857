import React, {  } from "react";
import { Dimensions, StyleSheet, View } from "react-native";

import Screen from "../components/Screen";

import { Text, H2, windowSize } from "../components/Themed";

export default function ProfileScreen(props) {

    console.log(windowSize('sm', 'md', 'lg'));
    const data = {
        dimensions: Dimensions.get("window")
    };
    const _data = JSON.stringify(data, null, 2);

    return (
        <Screen {...props}>
            <H2>Debug</H2>
            <Text styles={styles.pre}>{ _data } </Text>
        </Screen>
    );
}

const styles = StyleSheet.create({
    pre: {
        whiteSpace: 'pre-wrap'
    }
});
