import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import API from "../API";

import Screen from "../components/Screen";
import { em, Container, H4, Text, Bold, Paragraph, Alert } from "../components/Themed";
import Loading from "../components/Loading";
import Link from "../components/Link";

export default function PesticidesScreen(props) {
    const { navigation, route, ...otherProps } = props;
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pesticides, setPesticides] = useState([]);

    useEffect(() => {
        setLoading(true);
        API.Pesticides.index()
            .then((response) => {
                setPesticides(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                if (error.response ?? false) {
                    console.log("error response", error.response);
                } else {
                    console.log("error", error);
                }
            });
    }, []);

    let pesticides_children = [];
    pesticides.forEach((Pesticide) => {
        pesticides_children.push(
            <View key={Pesticide.id} style={styles.pesticideContainer}>
                <H4 selectable={true}>{Pesticide.name}</H4>
                <Text selectable={true}><Bold>EPA Registration Number:</Bold> {Pesticide.registration_number}</Text>
                <Text><Bold>Active Ingredient:</Bold> {Pesticide.active_ingredient}</Text>
            </View>
        );
    });

    return (
        <Screen navigation={navigation} title="Pesticides Data" {...props}>
            <Container>
                <Paragraph>The U.S. Environmental Protection Agency (EPA) requires and maintains registration of pesticides.</Paragraph>
                <Paragraph>Pesticide details may be searched for at the EPA's <Link url="https://ordspub.epa.gov/ords/pesticides/f?p=PPLS:1">Pesticide Product and Label System</Link> website.</Paragraph>
                <Paragraph>Below is a list of some pesticides used within the Community.</Paragraph>
            </Container>
            <Container>
                {isLoading && <Loading />}
                {error && <Alert type="danger">Error. Try again.</Alert>}
                {!isLoading && <View>{pesticides_children}</View>}
            </Container>
        </Screen>
    );
}

const styles = StyleSheet.create({
    container: {},
    loading: {},
    pesticideContainer: {
        marginBottom: em(1.5),
    },
});
