import React, { useState, useRef } from "react";
import { View, Dimensions, Platform } from "react-native";

import MapView from "react-native-maps";
import { default as WebMapView } from "react-native-web-maps";

const screen = Dimensions.get("window");
const ASPECT_RATIO = screen.width / screen.height;

export default function Map(props) {
    const { style, fields = [] } = props;
    // /let map = useRef(null);

    let markers = [];
    fields.forEach((Field, idx) => {
        const markerProps = {
            key: idx,
            coordinate: {
                latitude: parseFloat(Field.latitude),
                longitude: parseFloat(Field.longitude),
            },
            title: Field.name,
        };
        //console.log("markerProps", markerProps);
        if (Platform.OS == "web") {
            markers.push(<WebMapView.Marker {...markerProps} />);
        } else {
            markers.push(<MapView.Marker {...markerProps} />);
        }
    });

    const center = getCenter(fields);
    const delta = getDelta(fields) / 111 + 0.025;

    let mapProps = {
        region: {
            latitude: center.latitude,
            longitude: center.longitude,
            latitudeDelta: delta,
            longitudeDelta: delta * ASPECT_RATIO,
        },
        _initialCamera: {
            pitch: 0,
            altitude: 3000,
            zoom: 15,
        },
        style: style,
        options: {
            disableDefaultUI: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeId: "hybrid",
        },
        showCompass: false,
        rotateEnabled: false,
        pitchEnabled: false,
        toolbarEnabled: false,
        showsPointsOfInterest: false,
        showsUserLocation: false,
        mapType: "hybrid",
    };

    if (Platform.OS == "web") {
        return (
            <View>
                <WebMapView {...mapProps}>
                    {markers}
                </WebMapView>
            </View>
        );
    } else {
        return (
            <View>
                <MapView {...mapProps}>
                    {markers}
                </MapView>
            </View>
        );
    }
}

function getCenter(fields) {
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    fields.forEach((Field, idx) => {
        if(isNaN(Field.latitude) || isNaN(Field.longitude)){
            return;
        }
        let latitude = (Field.latitude * Math.PI) / 180;
        let longitude = (Field.longitude * Math.PI) / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
    });

    let total = fields.length;
    x = x / total;
    y = y / total;
    z = z / total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
        latitude: (centralLatitude * 180) / Math.PI,
        longitude: (centralLongitude * 180) / Math.PI,
    };
}

function getDelta(fields) {
    if (fields.length < 2) {
        return 0.01;
    }
    let lats = [];
    let longs = [];
    fields.forEach((Field, idx) => {
        lats.push(parseFloat(Field.latitude));
        longs.push(parseFloat(Field.longitude));
    });
    //console.log("lats longs", lats, longs);
    return calcCrow(Math.min(...lats), Math.min(...longs), Math.max(...lats), Math.max(...longs));
}

function calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

function toRad(Value) {
    return (Value * Math.PI) / 180;
}
