import { StyleSheet, View } from "react-native";
import React, { useState, useEffect } from "react";
import { H2, Container, Text, em } from "../Themed";

import CheckBox from "expo-checkbox";

import API from "../../API";
import userContext from "../../userContext";

export default function (props) {
    const [error, setError] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [Districts, setDistricts] = useState([]);
    const [User, setUser] = userContext();

    const updateSubscription = (district_num, type, toggle) => {
        let params = {
            district_num: district_num,
        };
        params[type] = toggle;
        setSubmitting(true);
        API.Subscription.update(params)
            .then((response) => {
                setSubmitting(false);
                console.log("response.data", response.data);
                updateSubscriptionUser(response.data);
            })
            .catch((error) => {
                console.log("catch", error);
                setSubmitting(false);
                if (typeof error.response != "undefined") {
                    console.log("error", error.response);
                } else {
                    // code is error.response.status
                }
            });
        return;
    };

    const handleCheckbox = function (district_num, type) {
        return (toggle) => {
            toggleCheckbox(district_num, type, toggle);
        };
    };

    const toggleCheckbox = function (district_num, type, toggle) {
        //console.log("handleCheckbox", district_num, type, toggle);
        updateSubscription(district_num, type, toggle);

        let TempUser = { ...User };
        for (var i in User.subscriptions) {
            if (TempUser.subscriptions[i].district_num == district_num) {
                //console.log('update sub', Subscription.id)
                TempUser.subscriptions[i][type] = toggle;
            }
        }
        setUser(TempUser);
    };

    const updateSubscriptionUser = (Subscription) => {
        //console.log('update subscription', Subscription.id)
        let TempUser = { ...User };
        for (var i in User.subscriptions) {
            if (TempUser.subscriptions[i].district_num == Subscription.district_num) {
                //console.log('update sub', Subscription.id)
                TempUser.subscriptions[i] = Subscription;
            }
        }
        setUser(TempUser);
    };

    useEffect(() => {
        if (typeof User.subscriptions != "object") {
            return [];
        }
        let _Districts = [];
        districts.forEach((district_num) => {
            let email = false;
            let sms = false;

            User.subscriptions.forEach((Subscription) => {
                if (Subscription.district_num == district_num) {
                    if (Subscription.email == 1) {
                        email = true;
                    }
                    if (Subscription.sms == 1) {
                        sms = true;
                    }
                }
            });

            //console.log("District", district_num, "email", email, "sms", sms);

            _Districts.push(
                <View style={styles.row} key={district_num}>
                    <View style={styles.col}>
                        <Text style={isSubmitting ? styles.disabled : {}}>District {district_num}</Text>
                    </View>
                    <View style={styles.col}>
                        <Text onPress={() => toggleCheckbox(district_num, "email", !email)} style={isSubmitting ? styles.disabled : {}}>Email</Text>
                        <CheckBox value={email} onValueChange={handleCheckbox(district_num, "email")} disabled={isSubmitting} style={styles.check_box} />
                    </View>
                    <View style={styles.col}>
                        <Text onPress={() => toggleCheckbox(district_num, "sms", !sms)} style={isSubmitting ? styles.disabled : {}}>Text Message</Text>
                        <CheckBox value={sms} onValueChange={handleCheckbox(district_num, "sms")} disabled={isSubmitting} style={styles.check_box} />
                    </View>
                </View>
            );
        });
        setDistricts(_Districts);
    }, [User]);

    return (
        <View {...props}>
            <Container style={styles.container}>
                <H2>Subscriptions</H2>
                {Districts}
            </Container>
        </View>
    );
}

const styles = StyleSheet.create({
    disabled: {
        color: "#aaaaaa"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "space-between",
        paddingVertical: em(0.5),
        borderBottomWidth: 1,
        borderBottomColor: "#eeeeee",
    },
    col: {
        flexDirection: "row",
        alignItems: "center",
    },
    check_box: {
        marginLeft: em(0.25),
    },
});

const districts = [1, 2, 3, 4, 5, 6, 7];
