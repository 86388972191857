import React from "react";
import { StyleSheet, Dimensions, View } from "react-native";
import Screen from "../components/Screen";
import { H1, H5, Text, Button, IconButton, em, windowSize } from "../components/Themed";
import { AntDesign } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import SplitContent from "../components/SplitContent";

import userContext from "../userContext";

export default function HomeScreen(props) {
    const { navigation, ...otherProps } = props;
    const [User, setUser] = userContext();

    const navTo = (name, options = {}) => {
        options.name = name;
        return () => navigation.navigate(name, options);
    };

    const header = (
        <View style={styles.header}>
            <LinearGradient colors={["#00000000", "#00000088"]} locations={[0, 0.2]} style={styles.header_grad}>
                <H1 style={styles.header_h1}>AERIAL APPLICATION NOTIFICATIONS</H1>
            </LinearGradient>
        </View>
    );

    const Icon = windowSize(false, true, true) ? <AntDesign size={em(1)} name="doubleright" /> : false;

    return (
        <Screen {...props} background={require("../assets/home-header.jpg")} header={header}>
            {User && (
                <SplitContent image={require("../assets/home/cellphone.jpg")} imagePos="left">
                    <H5 style={{fontSize: em(1)}} color="secondary">Welcome, {User.name}:</H5>
                    <Text style={{ paddingVertical: 12 }}>Manage your profile and notifications.</Text>
                    <Button size="xs" onPress={navTo("Profile")}>
                        User Settings {Icon}
                    </Button>
                </SplitContent>
            )}

            <SplitContent image={require("../assets/home/map.jpg")} imagePos="right">
                <H5 color="secondary">Search:</H5>
                <Text style={{ paddingVertical: 12 }}>Search for recent or planned aerial pesticide and fertilizer applications in your area. </Text>
                <Button size="xs" onPress={navTo("AerialApplications", {screen:"Search"})}>
                    SEARCH {Icon}
                </Button>
            </SplitContent>

            {!User && (
                <SplitContent image={require("../assets/home/cellphone.jpg")} imagePos="left">
                    <H5 color="secondary">Register For Alerts:</H5>
                    <Text style={{ paddingVertical: 12 }}>Planned aerial pesticide and fertilizer applications in your area. </Text>
                    <Button size="xs" onPress={navTo("Register")}>
                        REGISTER {Icon}
                    </Button>
                </SplitContent>
            )}
            <IconButton title="ABOUT GRIC DEQ" style={styles.IconButton} onPress={navTo("About")} />
            <IconButton title="QUESTION OR COMPLAINT FORM" style={styles.IconButton} onPress={navTo("Contact")} />
        </Screen>
    );
}

const styles = StyleSheet.create({
    IconButton: {
        paddingVertical: em(1.5),
    },
    header: {
        paddingTop: 60,
    },
    header_grad: {
        alignItems: "center",
    },
    header_h1: {
        color: "white",
        textAlign: "center",
        paddingBottom: em(1),
        marginTop: em(1),
    },
    header_subhead: {
        color: "white",
        textAlign: "center",
        paddingBottom: em(1),
        fontSize: em(1.1),
        width: "72%",
    },
    header_button: {
        marginBottom: em(1),
        width: "72%",
        borderColor: "white",
        borderWidth: 3,
    },
    map: {
        width: "100%",
        height: Dimensions.get("window").height,
    },
});
