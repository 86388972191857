import { em, IconButton } from "./Themed";
import React, { useState } from "react";

import API from "../API";

import userContext from "../userContext";

export default function SubscriptionButton(props) {
    const { district_num } = props;
    const [isLoading, setLoading] = useState(false);
    const [User, setUser] = userContext();

    const subscribe = () => {
        update_subscription(1);
    };

    const unsubscribe = () => {
        update_subscription(0);
    };

    const update_subscription = (value) => {
        setLoading(true);
        const params = {
            district_num: district_num,
            email: value,
            sms: value,
        };
        API.Subscription.update(params)
            .then((response) => {
                update_subscription_user(response.data);
                setLoading(false);
                console.log("subscribe response", response.data);
            })
            .catch((error) => {
                setLoading(false);
                if (error.response ?? false) {
                    console.log(error.response.data.error);
                    //setError(error.response.data.error);
                } else {
                    //setError("Internal Server Error");
                    // code is error.response.status
                }
            });
    };

    const update_subscription_user = (Subscription) => {
        let TempUser = {...User};
        for (var i in User.subscriptions) {
            if (TempUser.subscriptions[i].id == Subscription.id) {
                TempUser.subscriptions[i] = Subscription;
            }
        }
        setUser(TempUser);
        console.log(User);
    };

    const isSubscribed = () => {
        for (var i in User.subscriptions) {
            let Subscription = User.subscriptions[i];
            if (Subscription.district_num == district_num && (Subscription.email || Subscription.sms)) {
                return true;
            }
        }
        return false;
    };

    if (!User) {
        return [];
    }
    if (isSubscribed()) {
        return <IconButton title={`District ${district_num}: Unsubscribe`} onPress={unsubscribe} type="secondary" size="sm" Icon="bell" iconSize={em(1.5)} disabled={isLoading} />;
    } else {
        return <IconButton title={`District ${district_num}: Subscribe`} onPress={subscribe} type="success" size="sm" Icon="bell" iconSize={em(1.5)} disabled={isLoading} />;
    }
}
