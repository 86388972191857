import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import API from "../API";

import Screen from "../components/Screen";
import { H2, Container, InputGroup, TextInput, Select, InputError, Button, em } from "../components/Themed";
import { Formik } from "formik";
import * as Yup from "yup";

import userContext from "../userContext";

export default function ContactScreen(props) {
    const { navigation, route, ...otherProps } = props;
    const [User, setUser] = userContext();
    const [error, setError] = useState(false);
    const [sent, setSent] = useState(false);
    const params = typeof route.params == "object" ? route.params : {};
    console.log("params", params);

    const initialValues = {
        subject: "request",
        user_id: User ? User.id : 0,
        email: User ? User.email : "",
        name: User ? User.name : "",
        aerial_application_id: params.aerial_application_id ?? false,
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        message: Yup.string().max(600, "The message must be 600 or less characters."),
    });

    const onSubmit = (values, { setSubmitting }) => {
        setError(false);
        API.Contact.submit(values)
            .then((response) => {
                console.log("Contact then", response);
                setSubmitting(false);
                setSent(true);
                setTimeout(clear, 10000);
            })
            .catch((error) => {
                console.log("Contact catch", error);
                setSubmitting(false);
                if (error.response ?? false) {
                    console.log(error.response.data.error);
                    setError(error.response.data.error);
                } else if (error.message ?? false) {
                    setError(error.message);
                } else {
                    setError("Internal Server Error" + JSON.stringify(error, null, 2));
                    // code is error.response.status
                }
            });
        return;
    };

    const clear = () => {
        setError(false);
        setSent(false);
    }

    if (sent) {
        return (
            <Screen style={styles.screen} navigation={navigation} title="Contact GRIC DEQ" {...props}>
                <Container style={styles.container}>
                    <H2>Sent</H2>
                    <Text>Thank You.</Text>
                </Container>

                <Button style={styles.send_another} onPress={clear}>Send Another</Button>
            </Screen>
        );
    }

    return (
        <Screen style={styles.screen} navigation={navigation} title="Contact GRIC DEQ" {...props}>
            <Formik style={styles.form} initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, isSubmitting }) => (
                    <Container style={styles.container}>
                        <InputGroup>
                            <Text style={styles.label}>Subject:</Text>
                            <Select onValueChange={handleChange("subject")} selectedValue={values.subject} options={subjects} />
                        </InputGroup>

                        {values.aerial_application_id && (
                            <InputGroup>
                                <Text style={styles.label}>Aerial Application #:</Text>
                                <TextInput value={values.aerial_application_id.toString()} readonly />
                            </InputGroup>
                        )}

                        <InputGroup>
                            <Text style={styles.label}>Your Name:</Text>
                            <TextInput placeholder="Full Name" onChangeText={handleChange("name")} onBlur={handleBlur("name")} value={values.name} autoComplete="name" />
                            {errors.name && <InputError>{errors.name}</InputError>}
                        </InputGroup>

                        <InputGroup>
                            <Text style={styles.label}>Your Email Address:</Text>
                            <TextInput placeholder="Email Address" onChangeText={handleChange("email")} onBlur={handleBlur("email")} value={values.email} keyboardType="email-address" textContentType="emailAddress" autoComplete="email" />
                            {errors.email && <InputError>{errors.email}</InputError>}
                        </InputGroup>

                        <InputGroup>
                            <Text style={styles.label}>{getSubject(values.subject)}:</Text>
                            <TextInput placeholder="Message" onChangeText={handleChange("message")} onBlur={handleBlur("message")} value={values.message} multiline={true} numberOfLines={3} style={styles.textarea} />
                            {errors.message && <InputError>{errors.message}</InputError>}
                        </InputGroup>

                        <Button onPress={handleSubmit} title="Submit" disabled={!isValid || isSubmitting} />

                        {error && <InputError>{error}</InputError>}
                        {isSubmitting && <Text style={styles.loading}>Loading...</Text>}
                    </Container>
                )}
            </Formik>
        </Screen>
    );
}

const subjects = [
    { value: "request", label: "Request Information" },
    { value: "complaint", label: "Complaint" },
];

function getSubject(value) {
    let subject = "";
    subjects.forEach((Subject) => {
        if (value == Subject.value) {
            subject = Subject.label;
        }
    });
    return subject;
}

const styles = StyleSheet.create({
    form: {
        flex: 1,
        alignContent: "center",
    },
    label: {
        fontSize: em(1),
        paddingBottom: em(0.25),
    },
    textarea: {
        textAlignVertical: "top",
    },
    screen: {},
    container: {},
    loading: {},
    send_another: {
        marginBottom: em(1)
    }
});
