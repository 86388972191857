import React, { useContext } from "react";
import { StyleSheet, Text, View } from "react-native";
import API from "../API";

import Screen from "../components/Screen";
import { Button } from "../components/Themed";


import ProfileForm from "../components/Profile/ProfileForm";
import EmailForm from "../components/Profile/EmailForm";
import PhoneForm from "../components/Profile/PhoneForm";
import SubscriptionForm from "../components/Profile/SubscriptionForm";

import AppContext from "../AppContext";

export default function ProfileScreen(props) {

    const [accessToken, setAccessToken, User, setUser] = useContext(AppContext);

    const handleLogout = () => {
        API.Auth.logout();
        setUser(false);
        setAccessToken(false);
    };

    return (
        <Screen {...props}>
            <ProfileForm />

            <EmailForm />

            <PhoneForm />

            <SubscriptionForm />

            <Button title="Logout" onPress={handleLogout} type="danger" />
        </Screen>
    );
}

const styles = StyleSheet.create({
});
