import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import { IconButton, em, typeColor } from "./Themed";

export default function Drawer(props) {
    const { descriptors, ...otherProps } = props;
    const { state, navigation } = props;

    let currentRouteKey = "";
    if (state.history.length) {
        state.history.forEach(route => {
            if(route.type == "route"){
                currentRouteKey = route.key;
            }
        });
    }
    
    const Nav = (props) => {
        const show = true; //props.name != route.name;
        return show ? <IconButton style={styles.Nav} textStyle={{textAlign: "left"}} onPress={navTo(props.name)} Icon="arrow-right-circle" {...props} /> : false;
    };

    const navTo = (route_name) => {
        return () => {
            navigation.navigate(route_name);
        };
    };

    let navs = [];
    state.routes.forEach((route) => {
        const descriptor = descriptors[route.key];
        if (typeof descriptor.options.drawerLabel != "undefined" && route.key != currentRouteKey) {
            navs.push(<Nav name={route.name} title={descriptor.options.drawerLabel} key={route.key} />);
        }
    });

    return <ScrollView style={styles.container}>{navs}</ScrollView>;
    //
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: typeColor('secondary'),
        zIndex: 3, // works on ios
        elevation: 3, // works on android
        position: "absolute",
        top: 64,
        left: 0,
        width: "100%",
    },
    Nav: {
        paddingVertical: em(1.25),
        paddingHorizontal: em(0.5),
    },
});
