import React, { useState, useEffect } from "react";
import { StyleSheet, Text, useWindowDimensions } from "react-native";
import Screen from "../components/Screen";
import { BackButton, Container, em } from "../components/Themed";
import HTMLView from "react-native-htmlview";

import Loading from "../components/Loading";

import API from "../API";

export default function ContentScreen(props) {
    const { navigation, route, ...otherProps } = props;
    const [page, setPage] = useState({ title: "Loading", content: "Loading Content" });
    const { width } = useWindowDimensions();

    useEffect(() => {
        //console.log("route", route);
        API.Content.get(route.name)
            .then((response) => {
                setPage({ title: response.data.title, content: response.data.content });
            })
            .catch((error) => {
                if (error.response ?? false) {
                    console.log("error", error.response);
                    setPage({ title: route.name, content: error.response.data });
                } else {
                    setPage({ title: "Error", content: "Error loading page" });
                    // code is error.response.status
                }
            });
        navigation.setOptions({ title: page.title }); //headerTitle
    }, []);

    return (
        <Screen {...props} title={page.title}>
            <Container>
                {page.title == "Loading" && <Loading />}
                {page.title != "Loading" && <HTMLView value={page.content} stylesheet={styles} addLineBreaks={true} paragraphBreak=""/>}
            </Container>
            <BackButton navigation={navigation} />
        </Screen>
    );
}

const style = function (styles = {}) {
    styles.marginTop = 0;
    styles.marginBottom = em(0.5);
    styles.padding = 0;
    styles["white-space"] = "normal";
    return styles;
};

const Hx = function (size, styles = {}) {
    styles.fontSize = em(size);
    return style(styles);
};

const styles = StyleSheet.create({
    h1: Hx(1.4),
    h2: Hx(1.2),
    p: style(),
    ul: style({
        padding: 0,
    }),
    li: {
        marginLeft: em(0.125),
        paddingLeft: em(0.125),
    },
});
