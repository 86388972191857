import { Platform } from "react-native";
import axios from "axios";
import Storage from "./Storage";
import app from "./app.json";

let baseURL = "https://db.gricdeq.org/api";
switch (app.mode) {
    case "local":
        if (Platform.OS == "web") {
            baseURL = "http://gricdeq.localhost/api";
        }
        break;
}

async function authHeaders() {
    return {
        Accept: "application/json",
        Authorization: "Bearer " + (await Storage.auth_token.get()),
    };
}

const API = {
    client: axios.create({
        baseURL: baseURL,
    }),
    get: async function (action, params = {}, config = {}) {
        config.params = params;
        return await API.client.get(action, config);
    },
    authGet: async function (action, params, config = {}) {
        config.params = params;
        config.headers = await authHeaders();
        return await API.client.get(action, config);
    },
    post: async function (action, data, config = {}) {
        return await API.client.post(action, data, config);
    },
    authPost: async function (action, data, config = {}) {
        config.headers = await authHeaders();
        return await API.client.post(action, data, config);
    },
};

API.Auth = {
    accessToken: async function () {
        return Storage.auth_token.get();
    },
    loggedIn: async function () {
        let token = await Storage.auth_token.get();
        return token ? true : false;
    },
    login: function (email, password, device_name) {
        let data = {
            email: email,
            password: password,
            device_name: device_name,
        };
        let config = {};
        return API.client.post("/auth/login", data, config).then(function (response) {
            if (typeof response.data.access_token == "string") {
                Storage.auth_token.set(response.data.access_token);
                Storage.user.set(response.data.user);
            }
            return response;
        });
    },
    register: async function (data) {
        return await API.authPost("/auth/register", data);
    },
    user: async function () {
        return await API.authGet("/auth/me");
    },
    update_profile: async function (data) {
        return await API.authPost("/auth/update_profile", data);
    },
    update_email: async function (data) {
        return await API.authPost("/auth/update_email", data);
    },
    update_phone: async function (data) {
        return await API.authPost("/auth/update_phone", data);
    },
    logout: async function () {
        console.log("logout");
        Storage.auth_token.remove();
        Storage.user.remove();
    },
    one_time_code: async function (email) {
        return await API.authPost("/auth/one_time_code", { email: email });
    },
    one_time_code_confirm: async function (email, code) {
        return await API.authPost("/auth/one_time_code_confirm", { email: email, code: code });
    },
};

API.AerialApplication = {
    search: async function (params) {
        return await API.get("/aerial_application/search", params);
    },
    get: async function (id) {
        return await API.get("/aerial_application/get", { id: id });
    },
};

API.Content = {
    index: async function (params) {
        return await API.get("/content/", params);
    },
    get: async function (slug) {
        return await API.get("/content/get", { slug: slug });
    },
};

API.Pesticides = {
    index: async function (params) {
        return await API.get("/pesticides", params);
    },
};

API.Contact = {
    submit: async function (params) {
        return await API.post("/contact/submit", params);
    },
};

API.Subscription = {
    update: async function (params = {}) {
        return await API.authPost("/subscription/update", params);
    },
};

export default API;
