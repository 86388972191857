import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import API from "../API";

import Screen from "../components/Screen";
import { Container, InputGroup, TextInput, InputError, Button, em, IconButton } from "../components/Themed";
import { Formik } from "formik";
import * as Yup from "yup";

export default function OneTimeCodeScreen(props) {
    const { navigation, route, ...otherProps } = props;

    const [error, setError] = useState(false);

    const params = typeof route.params == "object" ? route.params : {};

    const initialValues = {
        email: params.email ? params.email : "",
    };

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
    });

    const navToConfirm = (values) => {
        navigation.navigate({
            name: "OneTimeCodeConfirm",
            params: values
        })
    };

    const onSubmit = (values, { setSubmitting }) => {
        setError(false);
        API.Auth.one_time_code(values.email)
            .then((response) => {
                console.log("One Time Code then", response);
                setSubmitting(false);
                navigation.navigate({
                    name: "OneTimeCodeConfirm",
                    params: values
                })
            })
            .catch((error) => {
                console.log("One Time Code catch", error);
                setSubmitting(false);
                if (error.response ?? false) {
                    console.log(error.response.data.error);
                    setError(error.response.data.error);
                } else if (error.message ?? false) {
                    setError(error.message);
                } else {
                    setError("Internal Server Error" + JSON.stringify(error, null, 2));
                    // code is error.response.status
                }
            });
        return;
    };

    return (
        <Screen style={styles.screen} navigation={navigation} title="One Time Code Login Code" {...props}>
            <Formik style={styles.form} initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, isSubmitting, setIsSubmitting }) => (
                    <View>
                    <Container style={styles.container}>
                        <InputGroup>
                            <Text style={styles.label}>Your Email Address:</Text>
                            <TextInput placeholder="Email Address" onChangeText={handleChange("email")} onBlur={handleBlur("email")} value={values.email} keyboardType="email-address" textContentType="emailAddress" autoComplete="email" />
                            {errors.email && <InputError>{errors.email}</InputError>}
                        </InputGroup>

                        <Button onPress={handleSubmit} title="Submit" disabled={!isValid || isSubmitting} />

                        {error && <InputError>{error}</InputError>}
                        {isSubmitting && <Text style={styles.loading}>Loading...</Text>}
                    </Container>
                    <IconButton title="Already have a code?" onPress={() => navToConfirm(values)} type="success" size="sm"  iconSize={em(1.5)} />
                    </View>
                )}
                
            </Formik>
        </Screen>
    );
}

const subjects = [
    { value: "request", label: "Request Information" },
    { value: "complaint", label: "Complaint" },
];

function getSubject(value) {
    let subject = "";
    subjects.forEach((Subject) => {
        if (value == Subject.value) {
            subject = Subject.label;
        }
    });
    return subject;
}

const styles = StyleSheet.create({
    form: {
        flex: 1,
        alignContent: "center",
    },
    label: {
        fontSize: em(1),
        paddingBottom: em(0.25),
    },
    textarea: {
        textAlignVertical: "top",
    },
    screen: {},
    container: {},
    loading: {},
});
