import React, { useContext } from "react";
import { StyleSheet, TouchableOpacity, View, Image } from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import { colors } from "./Themed"; 

import AppContext from "../AppContext";

export default function Header({navigation}) {
    const [accessToken, setAccessToken, User, setUser ] = useContext(AppContext);

    const openDrawer = () => {
        navigation.openDrawer()
    }

    const MenuIcon = () => (
        <TouchableOpacity onPress={openDrawer} style={[styles.icon, styles.MenuIcon]}>
            <Ionicons name="menu" size={40} color={colors.primary} />
        </TouchableOpacity>
    );

    const SearchIcon = () => (
        <TouchableOpacity onPress={() => navigation.navigate("AerialApplications")} style={[styles.icon, styles.SearchIcon]}>
            <Ionicons name="search-sharp" size={32} color="white" />
        </TouchableOpacity>
    );

    return (
        <View style={[styles.bar]}>
            <MenuIcon />
            <Image source={require("../assets/GRIC-DEQ.png")} style={styles.logo} />
            <SearchIcon />
        </View>
    );
}

const styles = StyleSheet.create({
    bar: {
        backgroundColor: colors.secondary + "BB",
        height: 64,
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between",
        marginBottom: 80
    },
    icon: {
        height: 64,
        width: 64,
        justifyContent: "center",
        alignItems: "center",
    },
    logo: {
        marginTop: 1,
        width: 170,
        height: 170,
        zIndex: 2
    },
    MenuIcon: {
        backgroundColor: "white",
    },
    SearchIcon: {
        backgroundColor: colors.secondary + "99",
    },
});
