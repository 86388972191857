import React, { useState, useContext } from "react";
import { StyleSheet, Text, View } from "react-native";
import API from "../API";

import Screen from "../components/Screen";
import { H2, Container, InputGroup, TextInput, Select, InputError, Button, em } from "../components/Themed";
import { Formik } from "formik";
import * as Yup from "yup";

import Storage from "../Storage";
import AppContext from "../AppContext";

export default function OneTimeCodeConfirmScreen(props) {
    const { navigation, route, ...otherProps } = props;

    const [accessToken, setAccessToken, User, setUser] = useContext(AppContext);
    const [error, setError] = useState(false);
    const params = typeof route.params == "object" ? route.params : {};
    console.log("params", params);

    const initialValues = {
        email: params.email ? params.email : "",
        code: ""
    };

    const lengthMessage = '5 digit code';
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
        code: Yup.number().min(10000, lengthMessage).max(99999, lengthMessage).required("Required")
    });

    const onSubmit = (values, { setSubmitting }) => {
        setError(false);
        API.Auth.one_time_code_confirm(values.email, values.code)
            .then((response) => {
                console.log("One Time Code Confirm then", response);
                //setSubmitting(false);
                Storage.auth_token.set(response.data.access_token)
                Storage.user.set(response.data.user);
                setAccessToken(response.data.access_token);
                setUser(response.data.user);

            })
            .catch((error) => {
                console.log("One Time Code Confirm catch", error);
                setSubmitting(false);
                if (error.response ?? false) {
                    console.log(error.response.data.error);
                    setError(error.response.data.error);
                } else if (error.message ?? false) {
                    setError(error.message);
                } else {
                    setError("Internal Server Error" + JSON.stringify(error, null, 2));
                    // code is error.response.status
                }
            });
        return;
    };

    return (
        <Screen style={styles.screen} navigation={navigation} title="Login With Code" {...props}>
            <Formik style={styles.form} initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, isSubmitting }) => (
                    <Container style={styles.container}>
                        <InputGroup>
                            <Text style={styles.label}>Your Email Address:</Text>
                            <TextInput placeholder="Email Address" onChangeText={handleChange("email")} onBlur={handleBlur("email")} value={values.email} keyboardType="email-address" textContentType="emailAddress" autoComplete="email" />
                            {errors.email && <InputError>{errors.email}</InputError>}
                        </InputGroup>

                        <InputGroup>
                            <Text style={styles.label}>Code:</Text>
                            <TextInput placeholder="#####" onChangeText={handleChange("code")} onBlur={handleBlur("code")} value={values.code} keyboardType="number-pad" />
                            {errors.code && <InputError>{errors.code}</InputError>}
                        </InputGroup>

                        <Button onPress={handleSubmit} title="Login" disabled={!isValid || isSubmitting} />

                        {error && <InputError>{error}</InputError>}
                        {isSubmitting && <Text style={styles.loading}>Loading...</Text>}
                    </Container>
                )}
            </Formik>
        </Screen>
    );
}

const subjects = [
    { value: "request", label: "Request Information" },
    { value: "complaint", label: "Complaint" },
];

function getSubject(value) {
    let subject = "";
    subjects.forEach((Subject) => {
        if (value == Subject.value) {
            subject = Subject.label;
        }
    });
    return subject;
}

const styles = StyleSheet.create({
    form: {
        flex: 1,
        alignContent: "center",
    },
    label: {
        fontSize: em(1),
        paddingBottom: em(0.25),
    },
    textarea: {
        textAlignVertical: "top",
    },
    screen: {},
    container: {},
    loading: {},
});
