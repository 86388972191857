import { StyleSheet, Text } from "react-native";
import React, { useState } from "react";
import { H2, Container, InputGroup, TextInput, InputError, Button, Alert, em } from "../Themed";
import { Formik } from "formik";
import * as Yup from "yup";

import API from "../../API";
import userContext from "../../userContext";

export default function (props) {
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);
    const [User, setUser] = userContext();

    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        password: Yup.string(),
    });

    const onSubmit = (values, { setSubmitting }) => {
        setError(false);
        setMessage(false);
        API.Auth.update_profile(values)
            .then((response) => {
                setSubmitting(false);
                console.log('update_profile response', response);
                setUser(response.data);
                setMessage("Profile updated.");
                return response;
            })
            .catch((error) => {
                console.log("catch", error);
                setSubmitting(false);
                if (typeof error.response != "undefined") {
                    console.log(error.response);
                    setError(error.response.data.message);
                } else {
                    setError("Internal Server Error");
                    // code is error.response.status
                }
            });
        return;
    };

    return (
        <Formik initialValues={{name: User.name, password: ""}} style={styles.form} validationSchema={validationSchema} onSubmit={onSubmit} {...props}>
            {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, isSubmitting, setSubmitting }) => (
                <Container>
                    <H2>Profile</H2>
                    <InputGroup>
                        <Text style={styles.label}>Full Name</Text>
                        <TextInput placeholder="Full Name" onChangeText={handleChange("name")} onBlur={handleBlur("name")} value={values.name} />
                        {errors.name && <InputError>{errors.name}</InputError>}
                    </InputGroup>

                    <InputGroup>
                        <Text style={styles.label}>Password</Text>
                        <TextInput placeholder="Password" onChangeText={handleChange("password")} onBlur={handleBlur("password")} value={values.password} secureTextEntry />
                        <Text style={styles.note}>Leave blank to not change.</Text>
                        {errors.password && <InputError>{errors.password}</InputError>}
                    </InputGroup>

                    <Button onPress={handleSubmit} disabled={!isValid || isSubmitting} title="Save Changes" />

                    {error && <Alert type="danger">{error}</Alert>}
                    {isSubmitting && <Text style={styles.loading}>Loading...</Text>}
                    {message && <Text style={styles.message}>{message}</Text>}
                </Container>
            )}
        </Formik>
    );
}

const styles = StyleSheet.create({
    form: {
        flex: 1,
        alignContent: "center",
    },
    label: {
        fontSize: em(1),
        paddingBottom: em(0.25),
    },
    note: {
        fontSize: em(.8),
        color: "#666666"
    },
    loading: {},
});
