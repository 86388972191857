import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { H2, Container, InputGroup, TextInput, InputError, Button, Alert, em } from "../Themed";
import { Formik } from "formik";
import * as Yup from "yup";

import API from "../../API";
import userContext from "../../userContext";

export default function (props) {
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);
    const [User, setUser] = userContext();

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
    });

    const onSubmit = (values, { setSubmitting }) => {
        setError(false);
        setMessage(false);
        API.Auth.update_email(values)
            .then((response) => {
                setSubmitting(false);
                console.log("response", response);
                setUser(response.data);
                setMessage("Email address updated. Please check your email.");
                return response;
            })
            .catch((error) => {
                console.log("catch", error);
                setSubmitting(false);
                if (typeof error.response != "undefined") {
                    console.log(error.response);
                    setError(error.response.data.message);
                } else {
                    setError("Internal Server Error");
                    // code is error.response.status
                }
            });
        return;
    };

    return (
        <Formik initialValues={{ email: User.email }} style={styles.form} validationSchema={validationSchema} onSubmit={onSubmit} {...props}>
            {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, isSubmitting, setSubmitting }) => (
                <Container style={styles.container}>
                    <H2>Update Email Address</H2>

                    <InputGroup>
                        <TextInput placeholder="Email Address" onChangeText={handleChange("email")} onBlur={handleBlur("email")} value={values.email} keyboardType="email-address" />
                        {errors.email && <InputError>{errors.email}</InputError>}
                    </InputGroup>

                    <Button onPress={handleSubmit} disabled={!isValid || isSubmitting} title="Save Changes" />

                    {error && <Alert type="danger">{error}</Alert>}
                    {isSubmitting && <Text style={styles.loading}>Loading...</Text>}
                    {message && <Text style={styles.message}>{message}</Text>}

                    {!User.email_verified_at &&
                        <Alert type="warning">
                            <Text>Your email address has not been verified yet.</Text>
                        </Alert>
                    }
                </Container>
            )}
        </Formik>
    );
}

const styles = StyleSheet.create({
    form: {
        flex: 1,
        alignContent: "center",
    },
    label: {
        fontSize: em(1),
        paddingBottom: em(0.25),
    },
    note: {
        fontSize: em(0.8),
        color: "#666666",
    },
    loading: {},
});
