import { StyleSheet, View, Text as DefaultText, TextInput as DefaultTextInput, TouchableOpacity, Dimensions, Platform } from "react-native";
import { AntDesign, Feather } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";

const dimensions = Dimensions.get("window");

export function windowSize(sm, md, lg) {
    const width = dimensions.width;
    if (width > 720) {
        return lg;
    }
    if (width > 401) {
        return md;
    }
    return sm;
}

export function fontSize() {
    var scale = windowSize(1, 1.2, 1.4);
    return 12 * scale * dimensions.fontScale;
}

export function em(size) {
    return size * fontSize();
}

export function Text(props) {
    const { style, color = "gray", ...otherProps } = props;
    const color_style = { color: typeColor(color) };

    return <DefaultText style={[styles.Text, color_style, style]} {...otherProps} />;
}

export function Bold(props) {
    const { style, ...otherProps } = props;
    return <Text style={[style, { fontWeight: "600" }]} {...otherProps}></Text>;
}

export function Paragraph(props) {
    const { style, ...otherProps } = props;
    return <Text style={[style, { marginBottom: em(0.5) }]} {...otherProps}></Text>;
}

function HX(X, props) {
    const { style, ...otherProps } = props;
    const _X = { 1: em(2), 2: em(1.8), 3: em(1.6), 4: em(1.4), 5: em(1.2) };
    const h_style = { fontSize: _X[X], fontWeight: "bold", textTransform: "uppercase" };
    return <Text style={[h_style, style]} {...otherProps} />;
}

export function H1(props) {
    return HX(1, props);
}

export function H2(props) {
    return HX(2, props);
}

export function H3(props) {
    return HX(3, props);
}

export function H4(props) {
    return HX(4, props);
}

export function H5(props) {
    return HX(5, props);
}

export function Container(props) {
    const { style, ...otherProps } = props;
    return <View style={[styles.Container, style]} {...otherProps} />;
}

export function InputGroup(props) {
    const { name, style, inputGroupStyle, ...otherProps } = props;
    return (
        <View style={[styles.InputGroup, inputGroupStyle]}>
            <View style={style} {...otherProps} />
        </View>
    );
}

export function InputError(props) {
    const { style, ...otherProps } = props;
    return <Text style={[styles.InputError, style]} {...otherProps} />;
}

export function TextInput(props) {
    const { style, placeholderTextColor = "#444444", ...otherProps } = props;
    return <DefaultTextInput style={[styles.Input, styles.TextInput, style]} placeholderTextColor={placeholderTextColor} {...otherProps} />;
}

export function Select(props) {
    const { style, options = [], children, ...otherProps } = props;
    let items = [];
    options.forEach((item, idx) => {
        items.push(<Picker.Item {...item} key={idx} />);
    });
    return (
        <Picker style={[styles.Input, styles.Select, style]} itemStyle={styles.SelectItem} numberOfLines={3} {...otherProps}>
            {items}
        </Picker>
    );
}

function SelectOption(settings) {
    const { item, getLabel } = settings;
    return (
        <View style={styles.optionContainer}>
            <Text>{getLabel(item)}</Text>
        </View>
    );
}

const ButtonStyles = StyleSheet.create({
    Button: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: em(0.25),
        paddingVertical: em(1.25),
    },
    ButtonText: {
        color: "#ffffff",
        fontSize: em(1),
        fontWeight: "bold",
        width: "100%",
        textAlign: "center",
        textTransform: "uppercase",
    },
});

export function Button(props) {
    let { style, textStyle = {}, color, type, size = "md", children, nowrap = false, ...otherProps } = props;

    let buttonStyle = { backgroundColor: typeof color == "undefined" ? typeColor(type) : color };

    if (size == "sm") {
        buttonStyle.paddingVertical = em(0.75);
    }
    if (size == "xs") {
        buttonStyle.paddingVertical = em(0.33);
    }

    const buttonTextColor = {
        color: typeof color == "undefined" ? typeTextColor(type) : color,
    };

    if (typeof otherProps.title != "undefined") {
        children = otherProps.title;

        otherProps.accessible = true;
        otherProps.accessibilityLabel = otherProps.title;
    }

    if (typeof otherProps.disabled != "undefined" && otherProps.disabled) {
        otherProps.accessibilityState = { disabled: true };
        otherProps.onPress = function () {
            return false;
        };
        buttonStyle.backgroundColor += "99";
    }

    return (
        <TouchableOpacity style={[ButtonStyles.Button, buttonStyle, style]} {...otherProps}>
            {!nowrap && (
                <Text style={[ButtonStyles.ButtonText, buttonTextColor, textStyle]} adjustsFontSizeToFit={true}>
                    {children}
                </Text>
            )}
            {nowrap && children}
        </TouchableOpacity>
    );
}

const IconButtonStyles = StyleSheet.create({
    button: {
        borderBottomColor: "#00000044",
        borderBottomWidth: 2,
        paddingHorizontal: em(windowSize(1, 1.8, 1.8)),
    },
    text: {
        width: "auto",
        textAlign: "left",
    },
    icon: {
        height: "100%",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        paddingHorizontal: em(0.25),
    },
});

export function IconButton(props) {
    let { title, style, Icon = "plus-circle", iconPos = "right", iconColor = "white", iconSize = em(2), children, ...otherProps } = props;

    if (windowSize(true, false, false)) {
        Icon = false;
    }

    if (typeof Icon == "string") {
        Icon = <Feather name={Icon} size={iconSize} color={iconColor} />;
    }

    return (
        <Button style={[IconButtonStyles.button, style]} nowrap={true} {...otherProps}>
            {iconPos == "left" && <View style={[IconButtonStyles.icon]}>{Icon}</View>}
            <Text style={[ButtonStyles.ButtonText, IconButtonStyles.text]} adjustsFontSizeToFit={true}>
                {title}
            </Text>
            {iconPos != "left" && <View style={[IconButtonStyles.icon]}>{Icon}</View>}
        </Button>
    );
}

const BackButtonStyles = StyleSheet.create({
    container: {
        padding: em(1),
        flexDirection: "row",
        flexWrap: "wrap",
    },
});

export function BackButton(props) {
    const { navigation, style, to, options = {}, ...otherProps } = props;

    const size = otherProps.size ?? 1.2;

    //see navigation.canGoBack()
    const press = () => {
        if (to) {
            navigation.navigate(to, options);
        } else if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate("Home");
        }
    };

    return (
        <TouchableOpacity style={[BackButtonStyles.container, style]} onPress={press} {...otherProps}>
            <AntDesign name="back" size={em(size)} color="black" />
            <Text style={{ fontSize: em(size) }}>Back</Text>
        </TouchableOpacity>
    );
}

const AlertStyles = StyleSheet.create({
    view: {
        paddingVertical: em(1),
        paddingHorizontal: em(0.5),
        marginVertical: em(0.5),
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
    },
    text: {
        paddingLeft: em(0.5),
    },
});

export function Alert(props) {
    const { type = "success", style, children } = props;
    const bg = {
        backgroundColor: typeColor(type) + "cc",
        borderColor: typeColor(type),
        borderWidth: 2,
    };

    const color = {
        color: typeTextColor(type),
    };

    const Icon = typeIcon(type);

    return (
        <View style={[AlertStyles.view, bg, style]} {...props}>
            {Icon !== false && <Icon color={color.color} size={em(1.5)} />}
            <Text style={[color, AlertStyles.text]}>{children}</Text>
        </View>
    );
}

export const colors = {
    primary: "#c15427",
    secondary: "#3e6783",
    success: "#00A592",
    danger: "#c12727",
    warning: "#EAEEFF",
    white: "#FFFFFF",
    gray: "#333333",
};

export const colors_text = {
    primary: "white",
    secondary: "white",
    success: "white",
    danger: "white",
    warning: "black",
    white: "white",
    gray: "#333333",
};

export const icons = {
    warning: function (props) {
        return <AntDesign name="warning" {...props} />;
    },
    danger: function (props) {
        return <AntDesign name="warning" {...props} />;
    },
};

export function typeColor(type) {
    if (typeof colors[type] != "undefined") {
        return colors[type];
    } else {
        return colors.primary;
    }
}

export function typeTextColor(type) {
    if (typeof colors_text[type] != "undefined") {
        return colors_text[type];
    } else {
        return colors_text.primary;
    }
}

export function typeIcon(type) {
    if (typeof icons[type] != "undefined") {
        return icons[type];
    } else {
        return false;
    }
}

export const styles = StyleSheet.create({
    Text: {
        fontSize: em(1),
        color: "#333333",
        flexShrink: 1,
    },
    InputGroup: {
        paddingVertical: em(0.5),
    },
    InputError: {
        color: "red",
    },
    Input: {
        fontSize: em(1),
        backgroundColor: "#eeeeee",
        borderColor: "#333333",
        width: "100%",
        borderWidth: 1,
        paddingHorizontal: em(0.5),
        paddingVertical: em(1.25),
    },
    TextInput: {},
    Select: {
        height: em(12),
    },
    SelectItem: {
        //height: em(4)
    },

    Container: {
        elevation: 100,
        padding: em(1),
        margin: em(1),
        backgroundColor: "#FFF",
        borderRadius: em(1),
        shadowColor: "black",
        shadowRadius: em(0.25),
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.6,
    },
});
