import { StyleSheet, Linking, Platform } from "react-native";
import { Text } from "./Themed";

export default function Link(props) {
    const { style, url, route, children, otherProps } = props;

    const navTo = () => {
        if (url) {
            if (Platform.OS == "web") {
                return window.open(url, "_blank");
            }
            if (Linking.canOpenURL(url)) {
                Linking.openURL(url);
            }
        }
        if (route) {
            navigation.navigate(route);
        }
    };

    return <Text style={[styles.link, style]} onPress={navTo} {...otherProps} >{children}</Text>;
}

const styles = StyleSheet.create({
    link: {
        color: "blue",
    },
});
