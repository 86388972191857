import React, {   } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { em, Text } from "../../components/Themed";

export default function SearchResult(props) {
    const { style, Result, ...otherProps } = props;

    return (
        <TouchableOpacity style={[styles.Result, style]} {...otherProps}>
            <NameValue name={Result.fields.length == 1 ? "Field:" : "Fields:"} value={Result.fields_display} />
            <NameValue name="When:" value={Result.date_display} />
        </TouchableOpacity>
    );
}

const NameValue = function ({ name, value }) {
    return (
        <View style={styles.NameValue__Container}>
            <Text style={styles.NameValue__Name}>{name}</Text>
            <Text style={styles.NameValue__Value}>{value}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    Result: {
        marginTop: em(1),
        padding: em(1),
        backgroundColor: "#cccccc",
    },
    NameValue__Container: {
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    NameValue__Name: {
        minWidth: em(6),
        paddingRight: em(1),
        fontWeight: "600"
    },
    NameValue__Value: {
        
    },
});
