import AsyncStorage from "@react-native-async-storage/async-storage";



const Storage = {};

const createStorage = (key) => {
    Storage[key] = {
        key: '@' + key,
        async get() {
            let data = await AsyncStorage.getItem(this.key);
            return data ? JSON.parse(data) : false;
        },
        async set(value) {
            let data = JSON.stringify(value);
            await AsyncStorage.setItem(this.key, data);
        },
        async remove() {
            await AsyncStorage.removeItem(this.key);
        },
    }
}

createStorage('auth_token');
createStorage('user');
createStorage('email');

export default Storage;