
import 'react-native-reanimated';
import "react-native-gesture-handler";

import React, { useEffect, useState } from "react";
import { Text, colors } from "./components/Themed";

import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import API from "./API";

import AppContext from "./AppContext";

// Screens
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ContactScreen from "./screens/ContactScreen";
import ContentScreen from "./screens/ContentScreen";
import DebugScreen from "./screens/DebugScreen";
import ProfileScreen from "./screens/ProfileScreen";
import AerialApplications from "./screens/AerialApplications";
import OneTimeCodeScreen from "./screens/OneTimeCodeScreen";
import OneTimeCodeConfirmScreen from "./screens/OneTimeCodeConfirmScreen";
import PesticidesScreen from './screens/PesticidesScreen';

import Drawer from "./components/Drawer";
import { windowSize } from "./components/Themed";

let i = 0;

const App = () => {
    const [accessToken, setAccessToken] = useState(undefined);
    const [User, setUser] = useState(undefined);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if(!accessToken){
            API.Auth.accessToken().then((_accessToken) => {
                setAccessToken(_accessToken);
            });
        }

        if (accessToken && !User) {
            API.Auth.user().then((response) => {
                setUser(response.data);
                setLoading(false);
            }).catch((error) => {
                
                if (error.response ?? false) {
                    console.log("Auth.user catch error.response", error.response);
                    if(error.response.status == 401){
                        API.Auth.logout();
                    }
                } else {
                    console.log("Auth.user catch error", error);
                }
            });
        } else {
            setLoading(false);
        }
    }, [accessToken]); // if accessToken changes

    const linking = {
        prefixes: ["https://app.gricdeq.org"],
    };

    const navigator = {
        screenOptions: {
            headerShown: false,
            drawerStyle: {
                backgroundColor: colors.gray + "cc",
                width: windowSize("90%", "90%", 620),
            },
        },
        overlayColor: "#ff0000",
        drawerContent: Drawer,
    };

    const DrawerNavigator = createDrawerNavigator();

    const AerialApplicationsStack = () => {
        const Stack = createNativeStackNavigator();
        return (
            <Stack.Navigator initialRouteName="Search" screenOptions={{headerShown: false}}>
                <Stack.Screen name="Search" component={AerialApplications.SearchScreen} />
                <Stack.Screen name="View" component={AerialApplications.ViewScreen} />
            </Stack.Navigator>
        );
    };

    if(isLoading){
        return <Text>Loading...</Text>
    }

    if(i++ > 1000){
        // intentionally kill the app, it's in a infinite loop
        const foo = 'bar';
        foo = false;
    }

    return (
        <AppContext.Provider value={[accessToken, setAccessToken, User, setUser]}>
            <NavigationContainer linking={linking}>
                <DrawerNavigator.Navigator initialRouteName="Home" {...navigator}>
                    <DrawerNavigator.Screen name="Home" component={HomeScreen} options={{ drawerLabel: "HOME" }} />

                    {/* AerialApplications */}
                    <DrawerNavigator.Screen name="AerialApplications" component={AerialApplicationsStack} options={{ drawerLabel: "SEARCH FOR AERIAL APPLICATIONS" }} />
                    {/* <DrawerNavigator.Screen name="AerialApplications" component={AerialApplicationsStack} options={{ display: "none" }} /> */}

                    <DrawerNavigator.Screen name="Contact" component={ContactScreen} options={{ drawerLabel: "QUESTION OR COMPLAINT FORM" }} />

                    {/* Content */}
                    <DrawerNavigator.Screen name="About" component={ContentScreen} options={{ drawerLabel: "ABOUT DEQ" }} />
                    <DrawerNavigator.Screen name="Pesticides" component={PesticidesScreen} options={{ drawerLabel: "PESTICIDES DATA" }} />
                    
                    <DrawerNavigator.Screen name="AerialApplicationsFaq" component={ContentScreen} options={{ drawerLabel: "Aerial Applications FAQ" }} />
                    <DrawerNavigator.Screen name="AerialApplicatorsPortal" component={ContentScreen} options={{ drawerLabel: "Aerial Applicators Portal" }} />

                    {!User && <DrawerNavigator.Screen name="Login" component={LoginScreen} options={{ drawerLabel: "LOGIN" }} />}
                    {!User && <DrawerNavigator.Screen name="Register" component={RegisterScreen} options={{ display: "none" }} />}
                    {!User && <DrawerNavigator.Screen name="OneTimeCode" component={OneTimeCodeScreen} options={{ display: "none" }} />}
                    {!User && <DrawerNavigator.Screen name="OneTimeCodeConfirm" component={OneTimeCodeConfirmScreen} options={{ display: "none" }} />}


                    {User && <DrawerNavigator.Screen name="Profile" component={ProfileScreen} options={{ drawerLabel: "USER SETTINGS" }} />}

                    {/* Hidden */}
                    <DrawerNavigator.Screen name="Privacy" component={ContentScreen} options={{ drawerItemStyle: { display: "none" } }} />
                    <DrawerNavigator.Screen name="TOU" component={ContentScreen} options={{ drawerItemStyle: { display: "none" } }} />
                    <DrawerNavigator.Screen name="Debug" component={DebugScreen} options={{ drawerItemStyle: { display: "none" } }} />
                </DrawerNavigator.Navigator>
            </NavigationContainer>
        </AppContext.Provider>
    );
};

export default App;
