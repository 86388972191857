import { StyleSheet, View, Linking, Platform, TouchableOpacity } from "react-native";
import { Text, colors, em, windowSize } from "./Themed";

import { FontAwesome } from "@expo/vector-icons";

import app from "../app.json";

export default function Footer(props) {
    const { style, navigation, lightColor, darkColor, children, ...otherProps } = props;

    if (typeof navigation == "undefined") {
        console.error("navigation undefined");
    }

    const navTo = (link_props) => {
        const { url, route } = link_props;
        return () => {
            if (url) {
                if (Platform.OS == "web") {
                    return window.open(url, "_blank");
                }
                if (Linking.canOpenURL(url)) {
                    Linking.openURL(url);
                }
            }
            if (route) {
                navigation.navigate(route);
            }
        };
    };

    const Link = (link_props) => {
        return <Text style={[styles.text, styles.link, link_props.style]} onPress={navTo(link_props)} {...link_props} />;
    };

    return (
        <View style={styles.container}>
            <Link url="https://www.gricdeq.org">WWW.GRICDEQ.ORG</Link>
            <Link url="https://www.gilariver.org">WWW.GILARIVER.ORG</Link>
            <Link url="https://www.mygilariver.com">WWW.MYGILARIVER.COM</Link>
            <Link route="TOU">Terms of Use</Link>
            <Link route="Privacy">Privacy</Link>
            <Link route="Contact">Contact</Link>
            <View style={styles.social}>
                <TouchableOpacity style={styles.socialIcon}>
                    <FontAwesome name="facebook-f" size={em(2.25)} color="white" />
                </TouchableOpacity>
                <TouchableOpacity style={styles.socialIcon}>
                    <FontAwesome name="youtube-play" size={em(2)} color="white" />
                </TouchableOpacity>
            </View>
            <Text style={[styles.copyright]}>{"\u00A9"} 2022 Gila River Indian Community. All rights reserved.</Text>
            <Text style={[styles.version]} onPress={navTo({route: "Debug"})}>v{app.expo.version}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.secondary,
        paddingTop: em(2),
        paddingBottom: em(.5),
        paddingHorizontal: em(1),
    },
    text: {
        paddingVertical: em(1),
        textAlign: "center",
        color: "white",
        fontWeight: "400",
    },
    link: {
        fontWeight: "800",
    },
    social: {
        flex: 1,
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        marginVertical: em(1.5)
    },
    socialIcon: {
        display: "flex",
        marginHorizontal: em(.75),
        width: em(3.5),
        height: em(3.5),
        borderRadius: em(3.5) * 0.5,
        borderColor: "white",
        borderWidth: windowSize(2, 2, 3),
        justifyContent: "center",
        alignItems: "center",
        paddingTop: em(0.1),
    },
    copyright: {
        fontSize: em(.75),
        textAlign: "center",
        color: "white",
    },
    version: {
        textAlign: "center",
        color: "white",
        fontSize: em(.75),
    }
});
