import React, { Component } from "react";
import { Animated, Easing, View, StyleSheet, Text } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { em } from "../components/Themed";

class Loading extends Component {
    spinValue = new Animated.Value(0);

    componentDidMount() {
        this.spin();
    }

    spin = () => {
        this.spinValue.setValue(0);

        Animated.timing(this.spinValue, {
            toValue: 1,
            duration: 1000,
            easing: Easing.linear,
            useNativeDriver: true,
        }).start(() => this.spin());
    };

    render() {
        const { style, children } = this.props;
        const rotate = this.spinValue.interpolate({ inputRange: [0, 1], outputRange: ["0deg", "360deg"] });

        return (
            <View style={styles.container}>
                <View style={styles.iconContainer}>
                    <Animated.View style={{ transform: [{ rotate }] }}>
                        <AntDesign name="loading2" size={em(2)} color="black" />
                    </Animated.View>
                </View>
                <View>
                    <Text style={styles.text}>Loading</Text>
                </View>
            </View>
        );
    }
}

export default Loading;

const styles = StyleSheet.create({
    container: {
        marginVertical: em(2),
    },
    iconContainer: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
    },
    text: {
        flex: 1,
        textAlign: "center",
        fontSize: em(1.3),
    },
});
