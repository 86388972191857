import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, Text, View } from "react-native";
import API from "../API";

import Screen from "../components/Screen";
import { H3, Container, InputGroup, TextInput, InputError, Button, em } from "../components/Themed";
import { Formik } from "formik";
import * as Yup from "yup";

import AppContext from "../AppContext";

export default function RegisterScreen(props) {
    const { navigation, ...otherProps } = props;
    const [accessToken, setAccessToken, User, setUser] = useContext(AppContext);
    const [loggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (accessToken) {
            setLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (loggedIn) {
            navigateBack();
            //navigation.navigate("Home");
            return;
        }
    }, []);

    const navigateBack = () => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate("Home");
        }
    };

    const navTo = (name) => {
        return () => navigation.navigate({ name: name });
    };

    const initialValues = { email: "", password: "" };
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        phone: Yup.string().matches(phoneRegExp, "Invalid Phone Number"),
        password: Yup.string().required("Required"),
    });

    const onSubmit = (values, { setSubmitting }) => {
        setError(false);
        API.Auth.register(values)
            .then((response) => {
                console.log("Register then", response);
                setAccessToken(response.data.access_token);
                setUser(response.data.user);
                navigateBack();
            })
            .catch((error) => {
                console.log("Register catch", error);
                setSubmitting(false);
                if (typeof error.response != 'undefined') {
                    console.log('error.response', error.response);
                    setError(error.response.data.message);
                } else {
                    setError("Internal Server Error" + JSON.stringify(error, null, 2));
                    // code is error.response.status
                }
            });
        return;
    };

    return (
        <Screen style={styles.screen} navigation={navigation} title="Register" {...props}>
            <Formik style={styles.form} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} disabled>
                {({ handleChange, handleBlur, handleSubmit, values, errors, isValid, isSubmitting }) => (
                    <Container style={styles.container}>
                        <InputGroup>
                            <TextInput placeholder="Full Name" onChangeText={handleChange("name")} onBlur={handleBlur("name")} value={values.name} />
                            {errors.name && <InputError>{errors.name}</InputError>}
                        </InputGroup>
                        <InputGroup>
                            <TextInput placeholder="Email Address" onChangeText={handleChange("email")} onBlur={handleBlur("email")} value={values.email} keyboardType="email-address" />
                            {errors.email && <InputError>{errors.email}</InputError>}
                        </InputGroup>
                        <InputGroup>
                            <TextInput placeholder="Phone Number" onChangeText={handleChange("phone")} onBlur={handleBlur("phone")} value={values.phone} keyboardType="phone-pad" />
                            {errors.phone && <InputError>{errors.phone}</InputError>}
                        </InputGroup>
                        <InputGroup>
                            <TextInput placeholder="Password" onChangeText={handleChange("password")} onBlur={handleBlur("password")} value={values.password} secureTextEntry />
                            {errors.password && <InputError>{errors.password}</InputError>}
                        </InputGroup>

                        <Button onPress={handleSubmit} title="Create Account" type="success" disabled={!isValid || isSubmitting} />

                        {error && <InputError>{error}</InputError>}
                        {isSubmitting && <Text style={styles.loading}>Loading...</Text>}
                    </Container>
                )}
            </Formik>

            <Button type="primary" title="Already have an account?" onPress={navTo("Login")} />
        </Screen>
    );
}

const styles = StyleSheet.create({
    form: {
        flex: 1,
        alignContent: "center",
    },
    screen: {},
    container: {},
    loading: {},
});

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
