import Screen from "../../components/Screen";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { em, Text, H1, H2, Container, InputGroup, InputError, Button, IconButton, Select } from "../../components/Themed";

import { Formik } from "formik";
import API from "../../API";

import SearchResult from "../../components/AerialApplications/SearchResult";

import Loading from "../../components/Loading";

export default function SearchScreen(props) {
    const { navigation, route, ...otherProps } = props;
    const [error, setError] = useState(false);

    const [results, setResults] = useState(false);

    const [isSubmitting, setSubmitting] = useState(false);

    const navTo = (name, params = {}) => {
        return () => navigation.navigate({ name: name, params: params });
    };

    const onSubmit = (values) => {
        setSubmitting(true);
        setError(false);
        setResults(false);
        API.AerialApplication.search(values)
            .then((response) => {
                setSubmitting(false);
                setResults(response.data);
                //console.log("results", response.data);
                return response;
            })
            .catch((error) => {
                setSubmitting(false);
                if (error.response ?? false) {
                    console.log("error", error.response);
                    setError(error.response.data.error);
                } else {
                    setError("Internal Server Error");
                    // code is error.response.status
                }
            });
    };

    const SearchResults = () => {
        if (results.length > 0) {
            let result_list = [];
            results.forEach((Result) => {
                const press = () => {
                    navigation.navigate("AerialApplications", { screen: "View", params: { id: Result.id } });
                };
                result_list.push(<SearchResult Result={Result} key={Result.id} onPress={press} />);
            });
            return (
                <Container>
                    <H2>RESULTS FOR AERIAL APPLICATIONS:</H2>
                    <Text>
                        {results.length} {results.length == 1 ? "Result" : "Results"}
                    </Text>
                    <View>{result_list}</View>
                </Container>
            );
        } else {
            return <Text style={styles.no_results}>No Results</Text>;
        }
    };

    return (
        <Screen {...props} title="Register For Alerts">
            <Formik style={styles.form} initialValues={initialValues} onSubmit={onSubmit}>
                {({ handleChange, handleSubmit, values, errors }) => (
                    <View>
                        <Container style={styles.container}>
                            <H2 color="primary">SEARCH FOR AERIAL APPLICATIONS:</H2>
                            <InputGroup>
                                <Text style={styles.label}>Search By Your District: </Text>
                                <Select onValueChange={handleChange("district")} selectedValue={values.district} options={districts} />
                            </InputGroup>

                            <Button onPress={handleSubmit} title="Search" disabled={isSubmitting} />

                            {error && <InputError>{error}</InputError>}
                        </Container>
                    </View>
                )}
            </Formik>

            {isSubmitting && <Loading />}
            {results !== false && <SearchResults results={results} />}

            <IconButton title="Aerial Applications FAQ" onPress={navTo("AerialApplicationsFaq")} />
        </Screen>
    );
}

const initialValues = {};

const districts = [
    { value: "-", label: "All Districts" },
    { value: "1", label: "District 1" },
    { value: "2", label: "District 2" },
    { value: "3", label: "District 3" },
    { value: "4", label: "District 4" },
    { value: "5", label: "District 5" },
    { value: "6", label: "District 6" },
    { value: "7", label: "District 7" },
];

const styles = StyleSheet.create({
    form: {
        flex: 1,
        alignContent: "center",
    },
    label: {
        fontSize: em(1),
        paddingBottom: em(0.25),
    },
    container: {},
    loading: {
        textAlign: "center",
        fontSize: em(2),
        fontWeight: "800",
        marginVertical: em(2),
    },
    no_results: {
        textAlign: "center",
    },
});
