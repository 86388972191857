import React, { useState, useEffect } from "react";
import { StyleSheet, View, Dimensions, Linking } from "react-native";

import Screen from "../../components/Screen";
import { em, Text, Container, Button, IconButton, BackButton, Paragraph, colors } from "../../components/Themed";
import SubscriptionButton from "../../components/SubscriptionButton";
import Map from "./Map";

import { AntDesign } from "@expo/vector-icons";

import API from "../../API";
import userContext from "../../userContext";

const mapSize = {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").width,
};

export default function ViewScreen(props) {
    const { navigation, route, ...otherProps } = props;

    const [Result, setResult] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [User] = userContext();

    const id = typeof route.params !== "undefined" ? route.params.id : "";

    const navTo = (name, params = {}) => {
        return () => navigation.navigate({ name: name, params: params });
    };

    console.log(navigation);

    useEffect(() => {
        setResult(false);
        setError(false);
        setLoading(true);

        API.AerialApplication.get(id)
            .then((response) => {
                setLoading(false);
                setResult(response.data);
                //console.log("Result", response.data);
                return response;
            })
            .catch((error) => {
                setLoading(false);
                if (error.response ?? false) {
                    console.log(error.response.data.error);
                    setError(error.response.data.error);
                } else {
                    setError("Internal Server Error");
                    // code is error.response.status
                }
            });
    }, [id]);

    const ResultComponent = () => {
        let subs = [];
        Result.districts.forEach((dis_num) => {
            subs.push(
                <View style={styles.sub} key={dis_num}>
                    <SubscriptionButton district_num={dis_num} />
                </View>
            );
        });

        const phone = Result.aerial_applicator.phone_number;
        const phone_url = "tel:" + phone;
        const PhoneLink = () => <Text style={styles.link} onPress={() => Linking.openURL(phone_url)}>{phone}</Text>

        return (
            <View>
                <NameValue name={Result.fields.length == 1 ? "Field:" : "Fields:"} value={Result.fields_display} />
                <NameValue name="When:" value={Result.date_display} />
                <NameValue name={Result.growers.length == 1 ? "Grower:" : "Growers:"} value={Result.growers_display} />
                <NameValue name={Result.pesticides.length == 1 ? "Pesticide:" : "Pesticides:"} value={Result.pesticides_display} />
                {Result.fertilizers && <NameValue name="Fertilizer:" value={Result.fertilizers} />}
                <NameValue name="Applicator:" value={Result.aerial_applicator.name} />
                <NameValue name="Applicator #:" value={<PhoneLink />} />

                <Map style={styles.map} fields={Result.fields} />

                {User && <Paragraph>Subscribe to Alerts</Paragraph>}
                {subs}

                {!User && (
                    <View>
                        <Paragraph>Register/Login to subscribe to notifications.</Paragraph>
                        <Button size="xs" onPress={navTo("Login")} type="secondary">
                            Login <AntDesign size={em(1)} name="doubleright" />
                        </Button>
                    </View>
                )}
            </View>
        );
    };

    return (
        <Screen {...props}>
            <BackButton navigation={navigation} to="AerialApplications" options={{ screen: "Search" }} />

            <Container>
                {isLoading && <Text>Loading...</Text>}
                {Result && <ResultComponent />}
            </Container>

            <View style={{ marginBottom: em(0) }}>
                <IconButton title="Aerial Applications FAQ" onPress={navTo("AerialApplicationsFaq")} />
                <IconButton title="Submit a Question or Complaint" onPress={navTo("Contact", { aerial_application_id: id })} />
            </View>
            <BackButton navigation={navigation} />

            <Text style={[styles.small, styles.center]}>Aerial Application #{id}</Text>
        </Screen>
    );
}

const NameValue = function ({ name, value }) {
    return (
        <View style={styles.NameValue__Container}>
            <Text style={styles.NameValue__Name}>{name}</Text>
            <Text style={styles.NameValue__Value}>{value}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    small: {
        fontSize: em(0.6),
    },
    center: {
        textAlign: "center",
    },
    NameValue__Container: {
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    NameValue__Name: {
        minWidth: em(6),
        paddingRight: em(1),
        fontWeight: "600",
    },
    NameValue__Value: {},
    mapLink: {
        width: "100%",
        height: 300,
    },
    map: {
        width: "100%",
        height: 300,
        marginTop: em(1),
        marginBottom: em(1),
    },
    sub: {
        marginTop: em(0),
    },
    button: {
        marginBottom: em(1),
    },
    link: {
        color: colors.secondary
    }
});
