import { StyleSheet, View, ScrollView, StatusBar, Platform, ImageBackground } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';

import Header from "./Header";
import Footer from "./Footer";

import { H1, colors } from "./Themed";

export default function Screen(props) {
    const { style, navigation, route, children, background = require("../assets/sky.jpg"), header, title, ...otherProps } = props;

    if (typeof navigation == "undefined") {
        console.error("navigation undefined");
    }

    return (
        <SafeAreaView style={styles.SafeArea}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={[styles.container, style]} {...otherProps}>
                <ImageBackground source={background} style={styles.background} resizeMode="cover">
                    <Header navigation={navigation} />
                    {title && <H1 style={styles.title}>{title}</H1>}
                    {header}
                </ImageBackground>
                <View style={styles.body}>{children}</View>
                <Footer navigation={navigation} />
            </ScrollView>
        </SafeAreaView>
    ); //<Drawer navigation={navigation} route={route} />
}

const styles = StyleSheet.create({
    SafeArea: {
        flex: 1,
        backgroundColor: colors.secondary,
        paddingTop: 0,
    },
    background: {
        minHeight: 200,
    },
    title: {
        color: "white",
        textAlign: "center",
        marginTop: 50,
        marginBottom: 20,
        textTransform: "uppercase"
    },
    container: {
        backgroundColor: "white",
    },
    body: {
        flex: 1,
        minHeight: "auto",
    },
});
