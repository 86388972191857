import { StyleSheet, View, Image } from "react-native";
import { Container, em } from "./Themed";

export default function SplitContent(props) {
    const { style, children, image, imagePos = "left", ...otherProps } = props;

    const TheImage = () => {
        const image_style = (imagePos == "left") ? styles.image_left : styles.image_right;
        return (
            <View style={[styles.col_image ]}>
                <Image style={[styles.image, image_style]} source={image} />
            </View>
        );
    };

    return (
        <Container style={[styles.container, style]} {...otherProps}>
            <View style={[styles.row]}>
                {imagePos == "left" && <TheImage />}
                <View style={[styles.col_content]}>{children}</View>
                {imagePos != "left" && <TheImage />}
            </View>
        </Container>
    );
}

const styles = StyleSheet.create({
    container: {

        padding: 0,
    },
    row: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
    },
    image_left: {
        borderTopLeftRadius: em(1),
        borderBottomLeftRadius: em(1)
    },
    image_right: {
        borderTopRightRadius: em(1),
        borderBottomRightRadius: em(1)
    },
    col_image: {
        width: "40%",
        height: "100%",
    },

    col_content: {
        width: "60%",
        padding: em(1),
        height: "100%",
    },
    image: {
        width: "100%",
        height: "100%",
    },
});
